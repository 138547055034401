/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronLeftSvgIcon } from '@components/Icons/ChevronLeftSvgIcon';
import { StringORNumber } from '@infrastructure/repositories/utils.repository';
import { IAttachment } from '@models/attachment.model';
import { theme } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isNumber } from '@shared/util/number-util';
import { StrategyLoadAttachments } from './FilePreviewModal';
import { URL_PARAM_ATTACHMENT_SELECTED_KEY } from '@constants/photoAlbum';
import { useTranslation } from 'react-i18next';

interface IFilePaginatorProps {
  currrentPosition?: number;
  totalAttachments?: number;
  fullFilesCollection: IAttachment[];
  fileSelected: IAttachment;
  strategyLoadAttachments: StrategyLoadAttachments;
  loadMoreAttachments?: () => void;
  setFileSelected: (IAttachment) => void;
}

export const FilePaginator = (props: IFilePaginatorProps) => {
  const { fullFilesCollection, totalAttachments, fileSelected, strategyLoadAttachments, loadMoreAttachments, setFileSelected } = props;
  //the assumption is that fullFilesCollection as versions included in reverse sort order

  const [currentPage, setCurrentPage] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [isPrevAllowed, setIsPrevAllowed] = useState(false);
  const [isNextAllowed, setIsNextAllowed] = useState(false);
  const [isCurrentFileLatestVersion, setIsCurrentFileLatestVersion] = useState(false);

  let [, setURLSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const findParentFileIndex = (fullFilesCollection, fileSelected) => {
    for (let findIndex = 0; findIndex < fullFilesCollection.length; findIndex++) {
      const file = fullFilesCollection[findIndex];

      if (file.versions && file.versions.length > 0) {
        for (let versionIndex = 0; versionIndex < file.versions.length; versionIndex++) {
          const version = file.versions[versionIndex];
          if (version.id === fileSelected.id) {
            if (versionIndex === 0) {
              setIsCurrentFileLatestVersion(true);
            } else {
              setIsCurrentFileLatestVersion(false);
            }
            return findIndex;
          }
        }
      } else {
        if (file.id === fileSelected.id) {
          setIsCurrentFileLatestVersion(true);
          return findIndex;
        }
      }
    }

    setIsCurrentFileLatestVersion(false);
    return -1;
  };

  useEffect(() => {
    const fileIndex = findParentFileIndex(fullFilesCollection, fileSelected);
    const currentPage = fileIndex + 1;
    setCurrentPage(currentPage);
    const totalFiles = (isNumber(totalAttachments) ? totalAttachments! : fullFilesCollection?.length) || 0;
    setTotalFiles(totalFiles);
  }, [fullFilesCollection, fileSelected]);

  useEffect(() => {
    if (currentPage === 1) {
      setIsPrevAllowed(false);
    }
    if (currentPage > 1) {
      setIsPrevAllowed(true);
    }
    if (currentPage < totalFiles) {
      setIsNextAllowed(true);
    }
    if (currentPage === totalFiles) {
      setIsNextAllowed(false);
    }
  }, [currentPage, totalFiles]);

  const setParamAttachmentSelectedId = (id: StringORNumber) => {
    setURLSearchParams(prev => {
      prev.set(URL_PARAM_ATTACHMENT_SELECTED_KEY, String(id));
      return prev;
    });
  };

  const onHandlePrev = () => {
    if (currentPage > 1) {
      const tempFile = fullFilesCollection[currentPage - 2];
      let prevFile;
      if (tempFile.versions && tempFile.versions.length > 0) {
        prevFile = tempFile.versions[0];
      } else {
        prevFile = tempFile;
      }
      if (prevFile) {
        setFileSelected(prevFile);
        setParamAttachmentSelectedId(String(prevFile.id));
      }
    }
  };

  const getPositionToAskForMoreAttachments = () => {
    return fullFilesCollection.length - 5;
  };

  const shouldAskForMoreAttachments = () => {
    const isOnDemmandStategy = strategyLoadAttachments === StrategyLoadAttachments.ON_DEMAND;
    const safePositionToAskForMoreAttachments = getPositionToAskForMoreAttachments();
    return isOnDemmandStategy && currentPage >= safePositionToAskForMoreAttachments;
  };

  const setNextFile = () => {
    const isAllowedNext = currentPage < totalFiles;
    if (isAllowedNext) {
      const tempFile = fullFilesCollection[currentPage];
      let nextFile;
      if (tempFile.versions && tempFile.versions.length > 0) {
        nextFile = tempFile.versions[0];
      } else {
        nextFile = tempFile;
      }
      if (nextFile) {
        setFileSelected(nextFile);
        setParamAttachmentSelectedId(String(nextFile.id));
      }
    }
  };

  const onHandleNext = () => {
    setNextFile();
    if (shouldAskForMoreAttachments()) {
      loadMoreAttachments?.();
    }
  };

  const {
    token: { colorTextSecondary, colorTextBase, colorTextTertiary, colorFillTertiary, colorFillSecondary },
  } = theme.useToken();

  useEffect(() => {
    const handleKeyDown = event => {
      switch (event.key) {
        case 'ArrowLeft':
          onHandlePrev();
          // Handle left arrow key press "<-"
          break;
        case 'ArrowRight':
          // Handle right arrow key press "->"
          onHandleNext();
          break;
        default:
          // Do nothing for other keys
          break;
      }
    };

    // Remove event listener when component unmounts
    window?.removeEventListener?.('keydown', handleKeyDown);

    // Add event listener when component mounts
    window?.addEventListener?.('keydown', handleKeyDown);

    // Clean up event listener when component unmounts
    return () => {
      window?.removeEventListener?.('keydown', handleKeyDown);
    };
  }, [onHandlePrev, onHandleNext]);

  if (totalFiles === 1 || !isCurrentFileLatestVersion) {
    return null;
  }

  return (
    <>
      <div
        className="flex flex-row items-center pt-6 pr-6 pb-6 pl-6"
        style={{
          borderRadius: 16,
          border: `1px solid ${colorFillSecondary}`,
          backgroundColor: colorFillTertiary,
        }}
      >
        <ChevronLeftSvgIcon
          className={`ml-4 mr-4 ${isPrevAllowed ? 'cursor-pointer' : 'cursor-not-allowed'}`}
          style={{ color: colorTextBase, opacity: isPrevAllowed ? 0.45 : 0.15 }}
          onClick={onHandlePrev}
        />
        <span className="mr-6 select-none" style={{ color: colorTextSecondary }}>
          {currentPage}
        </span>
        <span className="select-none" style={{ color: colorTextTertiary }}>
          {t('generic.of')} {totalFiles}
        </span>
        <ChevronLeftSvgIcon
          className={`mr-4 ml-4 ${isNextAllowed ? 'cursor-pointer' : 'cursor-not-allowed'}`}
          style={{ color: colorTextBase, opacity: isNextAllowed ? 0.45 : 0.15, transform: 'rotate(180deg)' }}
          onClick={onHandleNext}
        />
      </div>
    </>
  );
};
