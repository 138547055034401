import { RcFile } from 'antd/es/upload';
import { http } from '../http/axios.instance';
import axios from "axios";
import { IAttachment, IOnUploadProgress, IAttachmentToken } from '@models/attachment.model';
import { StringORNumber } from './utils.repository';

const path = 'attachments';
const pathExtended = 'attachments-extended';
const pathProjectDeliverables = 'project-deliverables';

export const attachmentExtendedRepository = {
  getAttachmentsOfProjectDeliverable: async (projectDeliverableId: string) => {
    const requestUrl = `${pathProjectDeliverables}/${projectDeliverableId}/get-attachments-of-project-deliverable`;
    const result = await http.get<IAttachment[]>(requestUrl);
    return result;
  },
  getByReferenceTypeAndReferenceId: async (referenceType: string, referenceId: StringORNumber) => {
    const requestUrl = `${path}/${referenceType}/${referenceId}`;
    const result = await http.get<IAttachment[]>(requestUrl);
    return result;
  },
  getDownloadSignedLink: async (attachmentId: string) => {
    const requestUrl = `${path}/${attachmentId}/get-download-signed-link`;
    return await http.get<string>(requestUrl);
  },
  getUploadSignedLink: async (attachmentId: string, onUploadProgress?: IOnUploadProgress) => {
    const requestUrl = `${path}/${attachmentId}/get-upload-signed-link`;
    return await http.get<string>(requestUrl, { ...(onUploadProgress && { onUploadProgress }) });
  },
  putFileToS3: async (file: RcFile | string | Blob, signedUrl: string, onUploadProgress?: IOnUploadProgress) => {
    const newAxios = axios.create({
      baseURL: signedUrl,
      timeout: 1800000,
      withCredentials: false,
    });
    return await newAxios.put(signedUrl, file, { ...(onUploadProgress && { onUploadProgress }) });
  },
  getAttachmentToken: async (attachmentId: string) => {
    const requestUrl = `/token?documentId=${attachmentId}`;
    const result = await http.get<IAttachmentToken>(requestUrl);
    return result;
  },
  createDeleteAnnotation: async (attachmentId: string, creation: boolean) => {
    const requestUrl = `${pathExtended}/${attachmentId}/annotations/${creation}`;
    return await http.post(requestUrl);
  },
};