import { Card } from "antd";
import "@styles/components/admin-item-card.less";
import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AvailableCurrenciesCard = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToCostGrouping = () => {
        navigate('/admin/application-configuration/cost-management/available-currencies');
    }

    return (
    <>
        <Card title={t("admin.availableCurrencies")} extra={<CustomButtonText type="default" onClick={navigateToCostGrouping}>{t("generic.view")}</CustomButtonText>} className="admin-card ant-card-no-body" />
    </>
    )
}