import { IDerivatives } from "@models/attachment.model";
import { DerivativesEnum } from "@models/enumerations/derivatives-enum.model";
import { isArrayWithValues } from "@shared/util/array-util";
import { COLOR_BORDER_INSIDE, LIGHT_BACKGROUND } from "../../FilePreviewModal";
import { WHITE_ABSOLUTE } from "@providers/ThemeProvider";
import { theme } from "antd";
import { useTranslation } from 'react-i18next';

interface IPaginatorInfoProps {
  derivatives: IDerivatives[];
  indexImageSelected: number;
  isFixed: boolean;
}

const FixedPaginationCss: React.CSSProperties = {
  position: 'sticky',
  top: '10px',
};

export const PaginatorInfo = (props: IPaginatorInfoProps) => {
  const { derivatives, indexImageSelected, isFixed } = props;

  const {
    token: { fontSizeLG },
  } = theme.useToken();

  const { t } = useTranslation();

  return (
    <>
      {/* Pagination Info */}
      <div className="flex flex-row items-center justify-center mt-10 " style={{ ...(isFixed && { ...FixedPaginationCss }) }}>
        <span
          className="pl-10 pr-10 pt-5 pb-5"
          style={{
            color: WHITE_ABSOLUTE,
            backgroundColor: LIGHT_BACKGROUND,
            fontSize: fontSizeLG,
            borderRadius: '4px',
            ...(isFixed && { border: `1px solid ${COLOR_BORDER_INSIDE}` }),
          }}
        >
          {t('generic.page')} {indexImageSelected + 1} {t('generic.of')}{' '}
          {isArrayWithValues(derivatives) ? derivatives?.filter(img => img?.derivativeType === DerivativesEnum.PREVIEW).length : 1}
        </span>
      </div>
    </>
  );
};
