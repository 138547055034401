import { Table } from "antd";
import { useTranslation } from 'react-i18next';

export default function TableHeader() {
  const { t } = useTranslation();
  return (
    <>
      <Table
        size="small"
        className="w-full"
        pagination={false}
        showHeader={false}
        dataSource={[{ id: 1, a: 'b', key: 0 }]}
        columns={[
          {
            title: 'Name',
            dataIndex: 'a',
            key: 'a',
            render: () => <span className="font-bold">{t('generic.name')}</span>,
          },
          {
            title: 'Version',
            dataIndex: 'a',
            key: 'b',
            width: 60,
            render: () => <span className="font-bold">{t('attachment.version')}</span>,
          },
          {
            title: 'Creator',
            dataIndex: 'a',
            key: 'c',
            width: 110,
            render: () => <span className="font-bold">{t('attachment.uploadedBy')}</span>,
          },
          {
            title: 'Upload Date',
            dataIndex: 'a',
            key: 'd',
            width: 110,
            render: () => <span className="font-bold">{t('attachment.uploadDate')}</span>,
          },
          {
            title: 'Actions',
            dataIndex: 'a',
            key: 'e',
            width: 150,
            render: () => <></>,
          },
        ]}
      />
    </>
  );
}