import { CommentReferenceEnum } from '@models/enumerations/comment-reference-enum.model';
import { RichTextEditor } from './RichTextEditor';
import { useTranslation } from 'react-i18next';

export interface ICommentGeneratorProps {
  entityType: CommentReferenceEnum;
  referenceId: number;
  onSuccessCreateComment?: () => void;
}

export const CommentGenerator = (props: ICommentGeneratorProps) => {
  const { entityType, referenceId, onSuccessCreateComment } = props;

  const { t } = useTranslation();

  return (
    <RichTextEditor
      showSend={true}
      entityType={entityType}
      referenceId={referenceId}
      placeholderText={t('generic.comment.writeNewComment')}
      onSuccessCreateComment={onSuccessCreateComment}
    />
  );
};