/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { gold } from "@ant-design/colors";
import { AppDispatch, useAppSelector } from "@store/store";
import { getEntity, updateEntity } from "@store/slices/location";
import { getEntities as getBrands } from "@store/slices/brand";
import { getEntities as getMarkets } from "@store/slices/market";

import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space
} from "antd";
import { useNavigate } from "react-router-dom";
import { ILocation } from "../../models/location.model";
import { DATE_FORMAT_DEFAULT } from '@shared/util/date-utils';
import dayjs from "dayjs";
import { Can } from "@components/Permisions/Can";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const LocationUpdate = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation(); 
  const { entity: locationEntity, updating } = useAppSelector((state) => state.Location);
  const brands = useAppSelector((state) => state.Brand.entities);
  const markets = useAppSelector((state) => state.Market.entities);

  const [open, setOpen] = useState(true);
  const [animateError, setAnimateError] = useState(false);
  const [form] = Form.useForm();

  const { id } = useParams<"id">();

  useEffect(() => {
    if (id) {
      dispatch(getEntity(id));
    }
    dispatch(getBrands({}));
    dispatch(getMarkets({}));
  }, [id]);

  useEffect(() => {
    if (locationEntity) {
      form.setFieldsValue({
        ...locationEntity,
        openDate: locationEntity?.openDate ? dayjs(locationEntity?.openDate) : null,
        closeDate : locationEntity.closeDate ? dayjs(locationEntity?.closeDate) : null
      });
      locationEntity?.brand &&
        form.setFieldValue("brand", JSON.stringify(locationEntity?.brand));
      locationEntity?.market &&
        form.setFieldValue("market", JSON.stringify(locationEntity?.market));
    }
  }, [form, locationEntity]);

  const onClose = (): void => {
    setOpen(false);
    dispatch(getEntity(`${locationEntity.id}`));
    navigate(`/location/${locationEntity.id}`);
  };

  const onFinish = (entity: ILocation): void => {
    entity = {
      ...entity,
      brand: entity?.brand ? JSON.parse(entity.brand as string) : null,
      market: entity?.market ? JSON.parse(entity.market as string) : null
    };
    dispatch(updateEntity({ ...locationEntity, ...entity })).then((result) => {
      const { code } = result.payload as { code: string } || null;
      if (!code) {
        // if not error codes, close the modal
        onClose();
      }
    });
  };

  const playAnimationOfError = () => {
    setAnimateError(true);
    setTimeout(() => {
      setAnimateError(false);
    }, 500);
  };

  const onFinishFailed = (errorInfo: any) => {
    playAnimationOfError();
  };

  const onSubmit = () => {
    form.submit();
  };

  const RequiredFieldLabel = (name: string) => {
    return (
      <span>
        {name}{" "}
        <b className="text-xs" style={{ color: gold[5] }}>
          *
        </b>
      </span>
    );
  };

  return (
    <>
      <Drawer
        title={`Edit ${t('generic.location')} Information`}
        width={500}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button type="primary" ghost onClick={onClose}>
              Cancel
            </Button>
            <Can I="EDIT" A="group.oak.siterise.service.dto.LocationDTO" effect="BLOCK">
              <Button onClick={onSubmit} type="primary" loading={updating}>
                Save
              </Button>
            </Can>
          </Space>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className={`${animateError && 'error-animation'}`}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label={RequiredFieldLabel('Name')} rules={[{ required: true, message: 'Please enter location name' }]}>
                <Input placeholder="Please enter location name" allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="locationNumber" label={t('location.locationNumber')}>
                <Input placeholder={`Please enter ${t('location.locationNumber')}`} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="brand" label={t('location.brand')}>
                <Select placeholder={`Please select a ${t('location.brand')}`} allowClear>
                  {brands.map(item => (
                    <Option value={JSON.stringify(item)} key={item.id}>
                      {item.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="market" label={t('location.market')}>
                <Select placeholder={`Please select a ${t('location.market')}`} allowClear>
                  {markets.map(item => (
                    <Option value={JSON.stringify(item)} key={item.id}>
                      {item.description}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={16}>
              <Form.Item name="openDate" label={t('location.openDate')}>
                <DatePicker name="openDate" className="w-full" format={DATE_FORMAT_DEFAULT} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={16}>
              <Form.Item name="closeDate" label={t('location.closeDate')}>
                <DatePicker name="openDate" className="w-full" format={DATE_FORMAT_DEFAULT} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default LocationUpdate;
