import { CustomButtonText } from "@components/Utils/CustomButtonText";
import { TabContainer } from "@components/Utils/TabContainer"
import { Card, Col, Row, Switch, theme } from "antd"
// import { getEntities } from "@store/slices/project-deliverable-reasons";
import { IUpdateSettingArgs, getEntities as getSettings } from "@store/slices/setting";
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@store/store";
import {
  ISetting,
  projectDeliverableReasonRequiredAlwaysKey,
  projectDeliverableReasonRequiredOpeningKey,
  projectDeliverableWeekendOverrideKey,
} from '@models/setting.model';
import { updateEntity } from '@store/slices/setting';
import { ProjectDeliverableReasonsCard } from './ProjectDeliverableReasonsCard';
import { useLayoutContex } from '@providers/LayoutProvider';
import { PhaseCard } from './PhasesCard';
import { BackButton } from '@components/Utils/BackButton';
import { useTranslation } from 'react-i18next';

export const ProjectDeliverablesTab = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    token: { colorSuccessText, colorTextTertiary, colorBorderSecondary, colorBgContainer },
  } = theme.useToken();
  // const { entities, loading } = useAppSelector((state) => state.ProjectDeliverableReasons);
  const { entities: settings } = useAppSelector(state => state.Setting);
  const [defaultAlwaysSetting, setDefaultAlwaysSetting] = useState<ISetting | undefined>(undefined);
  const [defaultOpeningSetting, setDefaultOpeningSetting] = useState<ISetting | undefined>(undefined);
  const [defaultWeekendOverrideSetting, setDefaultWeekendOverrideSetting] = useState<ISetting | undefined>(undefined);
  const [alwaysValue, setAlwaysValue] = useState<boolean>(false);
  const [openingValue, setOpeningValue] = useState<boolean>(false);
  const [weekendOverrideValue, setWeekendOverrideValue] = useState<boolean>(false);
  const { setHeaderLabelEntityState } = useLayoutContex();
  const { t } = useTranslation();

  useEffect(() => {
    setHeaderLabelEntityState(t('admin.projectDeliverables'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToDeliverables = () => {
    navigate('/admin/module-configuration/project-deliverables/deliverables');
  };

  const navigateToTemplates = () => {
    navigate('/admin/module-configuration/project-deliverables/templates');
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(getSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const setting = settings?.find(setting => setting.key === projectDeliverableReasonRequiredAlwaysKey);
    if (typeof setting !== 'undefined') {
      setDefaultAlwaysSetting(setting);
      setAlwaysValue(setting.value === 'true');
    }
    const setting2 = settings?.find(setting => setting.key === projectDeliverableReasonRequiredOpeningKey);
    if (typeof setting2 !== 'undefined') {
      setDefaultOpeningSetting(setting2);
      setOpeningValue(setting2.value === 'true');
    }
    const setting3 = settings?.find(setting => setting.key === projectDeliverableWeekendOverrideKey);
    if (typeof setting3 !== 'undefined') {
      setDefaultWeekendOverrideSetting(setting3);
      setWeekendOverrideValue(setting3.value === 'true');
    }
  }, [settings]);

  const onAlwaysChanged = (checked: boolean) => {
    setAlwaysValue(checked);
    let settingToSave = JSON.parse(JSON.stringify(defaultAlwaysSetting));
    settingToSave.value = `${checked}`;
    const args: IUpdateSettingArgs = {
      entity: settingToSave,
      onSuccess: () => {
        dispatch(getSettings());
      },
    };
    dispatch(updateEntity(args));
  };

  const onOpeningChanged = (checked: boolean) => {
    setOpeningValue(checked);
    let settingToSave = JSON.parse(JSON.stringify(defaultOpeningSetting));
    settingToSave.value = `${checked}`;
    const args: IUpdateSettingArgs = {
      entity: settingToSave,
      onSuccess: () => {
        dispatch(getSettings());
      },
    };
    dispatch(updateEntity(args));
  };

  const onWeekendOverrideChanged = (checked: boolean) => {
    setWeekendOverrideValue(checked);
    let settingToSave = JSON.parse(JSON.stringify(defaultWeekendOverrideSetting));
    settingToSave.value = `${checked}`;
    const args: IUpdateSettingArgs = {
      entity: settingToSave,
      onSuccess: () => {
        dispatch(getSettings());
      },
    };
    dispatch(updateEntity(args));
  };

  const navigateBack = () => {
    navigate('/admin/module-configuration');
  };

  return (
    <>
      <TabContainer>
        <div className="flex flex-col overflow-auto">
          <Row>
            <Col span={24}>
              <div
                className="flex flex-row items-center pl-10 pt-11 pb-11"
                style={{
                  borderTop: `1px solid ${colorBorderSecondary}`,
                  borderBottom: `1px solid ${colorBorderSecondary}`,
                  backgroundColor: colorBgContainer,
                }}
              >
                <BackButton onClick={navigateBack}>{t('admin.moduleConfiguration')}</BackButton>
              </div>
            </Col>
          </Row>
        </div>
        <div style={{ padding: '16px 24px' }}>
          <Card
            title={t('admin.deliverables')}
            extra={
              <CustomButtonText type="default" onClick={navigateToDeliverables}>
                {t('generic.view')}
              </CustomButtonText>
            }
            className="ant-card-no-body admin-card"
          />
          <PhaseCard />
          <Card title={t('admin.projectDeliverable.scheduleSettings')} className="admin-card">
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>{t('admin.projectDeliverable.weekendOverride')}</div>
              <div style={{ flex: 0, marginRight: '1rem', color: weekendOverrideValue ? colorSuccessText : colorTextTertiary }}>
                {weekendOverrideValue ? 'Enabled' : 'Disabled'}
              </div>
              <div style={{ flex: 0 }}>
                <Switch checked={weekendOverrideValue} onChange={onWeekendOverrideChanged} />
              </div>
            </div>
          </Card>
          <Card title={t('admin.projectDeliverable.changeReasons')} className="admin-card">
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>{t('admin.projectDeliverable.alwaysRequired')}</div>
              <div style={{ flex: 0, marginRight: '1rem', color: alwaysValue ? colorSuccessText : colorTextTertiary }}>
                {alwaysValue ? 'Enabled' : 'Disabled'}
              </div>
              <div style={{ flex: 0 }}>
                <Switch checked={alwaysValue} onChange={onAlwaysChanged} />
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <div style={{ flex: 1 }}>{t('admin.projectDeliverable.onlyForOpening')}</div>
              <div style={{ flex: 0, marginRight: '1rem', color: openingValue ? colorSuccessText : colorTextTertiary }}>
                {openingValue ? 'Enabled' : 'Disabled'}
              </div>
              <div style={{ flex: 0 }}>
                <Switch checked={openingValue} onChange={onOpeningChanged} />
              </div>
            </div>
          </Card>
          <ProjectDeliverableReasonsCard />
          <Card
            title="Templates"
            extra={
              <CustomButtonText type="default" onClick={navigateToTemplates}>
                {t('generic.view')}
              </CustomButtonText>
            }
            className="ant-card-no-body admin-card"
          />
        </div>
      </TabContainer>
    </>
  );
};