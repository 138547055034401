
import { ICurrency } from '@models/currency.model';
import { createAsyncThunk, createSlice, isFulfilled, isPending } from '@reduxjs/toolkit';
import { currencyService } from '@services/currency.service';
import { serializeAxiosError } from "../reducer.util";
import { cleanEntity } from '@shared/util/entity-utils';

export const getActiveEntities = createAsyncThunk(
    'currency/fetch_active_entity_list',
    async () => {
        return currencyService.getActiveCurrencies();
    },
    { serializeError: serializeAxiosError }
);

export const getEntities = createAsyncThunk(
    'currency/fetch_entity_list',
    async () => {
        return currencyService.getCurrencies();
    },
    { serializeError: serializeAxiosError }
);

interface ICurrencyState {
    loading: boolean;
    errorMessage: string | null;
    activeEntities: ReadonlyArray<ICurrency>;
    entities: ReadonlyArray<ICurrency>;
}

const initialState: ICurrencyState = {
    loading: false,
    errorMessage: null,
    activeEntities: [],
    entities: []
};

export const partialUpdateEntities = createAsyncThunk(
    'market/update_entity',
    async (entities: ICurrency[], thunkAPI) => {
        for (let i = 0; i < entities.length; i++) {
            const e = entities[i];
            e.id && await currencyService.partialUpdateCurrency(e.id, cleanEntity(e));
        };
      thunkAPI.dispatch(getEntities());
    },
    { serializeError: serializeAxiosError }
  );

export const CurrencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(getActiveEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    activeEntities: data
                };
            })
            .addMatcher(isPending(getActiveEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isFulfilled(getEntities), (state, action) => {
                const { data } = action.payload;
                return {
                    ...state,
                    loading: false,
                    entities: data
                };
            })
            .addMatcher(isPending(getEntities, partialUpdateEntities), state => {
                state.errorMessage = null;
                state.loading = true;
            })
            .addMatcher(isFulfilled(partialUpdateEntities), (state) => {
                state.loading = false;
            })
    }
});

// Reducer
export default CurrencySlice.reducer;

