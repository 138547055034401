import { Tooltip, theme } from 'antd';
import { MarkupModeSvgIcon } from '@components/Icons/MarkupModeSvgIcon';
import { useTranslation } from 'react-i18next';
import { If } from '@components/Utils/Structural';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface IMarkupModeButtonProps {
  handleOnClick: () => void;
  isActive: boolean;
  loading?: boolean;
}

export const MarkupModeButton = (props: IMarkupModeButtonProps) => {
  const { handleOnClick, isActive, loading = false } = props;
  const {
    token: { colorPrimary, colorTextTertiary },
  } = theme.useToken();

  const { t } = useTranslation();

  return (
    <Tooltip placement="bottom" title={t('attachment.panels.markup')}>
      <If condition={loading}>
        <Spin indicator={<LoadingOutlined spin />} />
      </If>
      <If condition={!loading}>
        <MarkupModeSvgIcon
          className="cursor-pointer"
          onClick={handleOnClick}
          height="18"
          width="18"
          style={{ color: isActive ? colorPrimary : colorTextTertiary }}
          isActive={isActive}
        />
      </If>
    </Tooltip>
  );
};
