import { EyeOutlined } from "@ant-design/icons";
import { IRfiCategory } from "@models/rfi-category.model";
import { IUser } from "@models/user.model";
import { Alert, Button, Col, Divider, Row } from "antd";
import { useEffect, useState } from "react";
import { Rfi } from "../shared/Rfi";
import { useCreateRfiContext } from "./CreateRfiContext";
import { ICreateRfiStepProps } from "./RfiCreate";
import { useAuth } from "@providers/AuthProvider";

export const RfiCreateStepTwo = (props: ICreateRfiStepProps) => {

    const createRfiContext = useCreateRfiContext();
    const [category, setCategory] = useState<IRfiCategory | undefined>(undefined);
    const [assignedTo, setAssignedTo] = useState<IUser | undefined>(undefined);
    const [following, setFollowing] = useState<IUser[]>([]);
    const { user: currentUser } = useAuth();

    useEffect(() => {
        if (createRfiContext) {
            if (createRfiContext?.category) setCategory(JSON.parse(createRfiContext.category) as IRfiCategory);
            if (createRfiContext?.assignedTo) setAssignedTo(JSON.parse(createRfiContext.assignedTo) as IUser);
            if (createRfiContext?.allFollowers) setFollowing(createRfiContext.allFollowers);
        }

    }, [createRfiContext])

    const confirm = () => {
        props.nextStep?.();
    }

    const goBack = () => {
        props.previousStep?.();
    }

    return (
      <div className="pl-10 pr-10">
        <Divider type="horizontal" className="mt-15 mb-15"></Divider>
        <Row>
          <Col span={24} className="mb-20">
            <Alert
              message={
                <div>
                  <EyeOutlined className="ant-alert-icon" style={{ fontSize: '16px', marginInlineEnd: 8 }} />
                  RFI Preview
                </div>
              }
              description={
                <div style={{ maxWidth: '450px', margin: 'auto' }}>
                  <div>You are currently previewing the RFI you created.</div>
                  <div>
                    To make any changes, simply click on <strong>"Go back and edit"</strong>.
                  </div>
                  <div className="pt-20">
                    Once you click <strong>"Confirm and create"</strong>, the RFI will be generated, and notifications will be sent to the
                    relevant stakeholders.
                  </div>
                </div>
              }
              type="info"
              className="text-center"
            />
          </Col>
        </Row>
        <Rfi
          number={createRfiContext?.number}
          categoryName={category?.name}
          questionPayload={createRfiContext?.questionPayload}
          scheduleImpact={createRfiContext?.scheduleImpact?.toString()}
          costImpact={createRfiContext?.costImpact?.toString()}
          dueDate={createRfiContext?.dueDate?.toDate()}
          creatorUserId={currentUser?.id}
          createdByFirstName={currentUser?.firstName}
          createdByLastName={currentUser?.lastName}
          createdByEmail={currentUser?.email}
          assignedTo={assignedTo}
          following={following}
        />
        <Row>
          <Col span={24} className="text-right">
            <Button type="link" onClick={goBack} className="mr-5">
              {'< Go back and edit'}
            </Button>
            <Button type="primary" onClick={confirm}>
              {'Confirm and create >'}
            </Button>
          </Col>
        </Row>
      </div>
    );
}