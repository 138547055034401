import { InformationSvgIcon } from "@components/Icons/InformationSvgIcon";
import { Tooltip, theme } from "antd";
import { useTranslation } from 'react-i18next';

interface IInfoButonProps {
  handleOnClick?: () => void;
  isActive: boolean;
}

export const InfoButon = (props: IInfoButonProps) => {
  const { handleOnClick, isActive } = props;

  const {
    token: { colorPrimary, colorTextTertiary },
  } = theme.useToken();

  const { t } = useTranslation();

  return (
    <Tooltip placement="bottom" title={t('attachment.panels.info')}>
      <InformationSvgIcon
        className="cursor-pointer"
        {...(handleOnClick && { onClick: handleOnClick })}
        onClick={handleOnClick}
        height="22"
        width="22"
        style={{ color: isActive ? colorPrimary : colorTextTertiary }}
      />
    </Tooltip>
  );
};
