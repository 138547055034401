import { SVGProps } from 'react';

type IProps = SVGProps<SVGSVGElement>;

interface MarkupModeSvgIconProps extends IProps {
  isActive: boolean;
}

export const MarkupModeSvgIcon = (props: MarkupModeSvgIconProps) => {
  const { isActive, ...restProps } = props;
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <path
        d="M1 18V13.75L14.175 0.6C14.375 0.4 14.6 0.25 14.85 0.15C15.1 0.0500001 15.35 0 15.6 0C15.8667 0 16.121 0.0500001 16.363 0.15C16.605 0.25 16.8173 0.4 17 0.6L18.4 2C18.6 2.18333 18.75 2.396 18.85 2.638C18.95 2.88 19 3.134 19 3.4C19 3.65 18.95 3.9 18.85 4.15C18.75 4.4 18.6 4.625 18.4 4.825L5.25 18H1ZM3 16H4.4L14.225 6.2L13.525 5.475L12.8 4.775L3 14.6V16ZM13.525 5.475L12.8 4.775L14.225 6.2L13.525 5.475ZM11 18C12.2333 18 13.375 17.6917 14.425 17.075C15.475 16.4583 16 15.6 16 14.5C16 13.9 15.8417 13.3833 15.525 12.95C15.2083 12.5167 14.7833 12.1417 14.25 11.825L12.775 13.3C13.1583 13.4667 13.4583 13.65 13.675 13.85C13.8917 14.05 14 14.2667 14 14.5C14 14.8833 13.6957 15.2293 13.087 15.538C12.4783 15.8467 11.7827 16.0007 11 16C10.7167 16 10.4793 16.096 10.288 16.288C10.0967 16.48 10.0007 16.7173 10 17C10 17.2833 10.096 17.521 10.288 17.713C10.48 17.905 10.7173 18.0007 11 18ZM1.575 10.35L3.075 8.85C2.74167 8.71667 2.479 8.57933 2.287 8.438C2.095 8.29667 1.99933 8.15067 2 8C2 7.8 2.15 7.6 2.45 7.4C2.75 7.2 3.38333 6.89167 4.35 6.475C5.81667 5.84167 6.79167 5.26667 7.275 4.75C7.75833 4.23333 8 3.65 8 3C8 2.08333 7.63333 1.35433 6.9 0.813C6.16667 0.271667 5.2 0.000666667 4 0C3.25 0 2.579 0.133333 1.987 0.4C1.395 0.666667 0.941 0.991667 0.625 1.375C0.441667 1.59167 0.366667 1.83333 0.4 2.1C0.433333 2.36667 0.558333 2.58333 0.775 2.75C0.991667 2.93333 1.23333 3.00833 1.5 2.975C1.76667 2.94167 1.99167 2.83333 2.175 2.65C2.40833 2.41667 2.66667 2.25 2.95 2.15C3.23333 2.05 3.58333 2 4 2C4.68333 2 5.18767 2.1 5.513 2.3C5.83833 2.5 6.00067 2.73333 6 3C6 3.23333 5.85433 3.44567 5.563 3.637C5.27167 3.82833 4.60067 4.166 3.55 4.65C2.21667 5.23333 1.29167 5.76267 0.775 6.238C0.258333 6.71333 0 7.30067 0 8C0 8.53333 0.141667 8.98767 0.425 9.363C0.708333 9.73833 1.09167 10.0673 1.575 10.35Z"
        fill={isActive ? '#1890FF' : '#292D2D'}
        fillOpacity={isActive ? '1' : '0.45'}
      />
    </svg>
  );
};
