import { BackButton } from "@components/Utils/BackButton"
import { TabContainer } from "@components/Utils/TabContainer"
import { useLayoutContex } from "@providers/LayoutProvider";
import { Row, Col, theme, Badge , Button, Switch, Popconfirm } from "antd"
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "@styles/components/admin-currencies-table.less";
import { ExclamationCircleFilled, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "@store/store";
import { getEntities, partialUpdateEntities } from "@store/slices/currency";
import { ICurrency } from "@models/currency.model";
import { asyncLaunchNotification } from "@store/slices/notification";

export const AvailableCurrenciesView = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { setHeaderLabelEntityState } = useLayoutContex();

    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [currencies, setCurrencies] = useState<ICurrency[]>([]);
    
    useEffect(() => {
        setHeaderLabelEntityState(t("admin.availableCurrencies"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadCurrencies = () => {
        dispatch(
          getEntities()
        );
    }
    
      useEffect(() => {
        loadCurrencies();
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    const { entities } = useAppSelector((state) => state.Currency);

    useEffect(() => {
        setCurrencies([...entities]);
    }, [entities]);

    const { token: { 
        colorPrimary, 
        colorTextDisabled, 
        colorBorderSecondary, 
        colorBgContainer, 
        colorBorder, 
        colorBgBase, 
        colorText,
        colorPrimaryBg,
        colorFillTertiary } } = theme.useToken();

    const navigateBack = () => {
        navigate('/admin/application-configuration/cost-management');
    }

    const onEdit = () => {
        setIsEditing(true);
        setCurrencies([...entities])
    }

    const onCancel = () => {
        setIsEditing(false);
        setCurrencies([...entities])
    }

    const onSave = () => {
        const currenciesToUpdate = currencies.filter(c => entities.some(e => e.id === c.id && e.active !== c.active));
        dispatch(partialUpdateEntities(currenciesToUpdate.map(x => { return { id: x.id, active: x.active }})))
            .then(() => {
                dispatch(asyncLaunchNotification({
                    type: "success",
                    config: {
                      message: `Changes saved`,
                      description: `Your changes have been saved successfully`
                    }
                }))
                loadCurrencies();
                setIsEditing(false)
            })
    }

    const onClickVisibility = (id: number | undefined) => {
        if (!id) {
            return;
        }
        const idx = currencies.findIndex(x => x.id === id);
        if (idx === -1) {
            return;
        }
        const updatedEntities = JSON.parse(JSON.stringify(currencies));
        updatedEntities[idx].active = !updatedEntities[idx].active;
        setCurrencies(updatedEntities)
    }

    const currenciesSorted = useMemo(() => {
        const sorted = [...currencies];
        sorted.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        return sorted;
    }, [currencies]);

    const unsavedChangesCount = useMemo(() => {
        if (!isEditing) {
            return 0;
        }
        return currencies.filter(c => entities.some(e => e.id === c.id && e.active !== c.active)).length;
    }, [currencies, entities, isEditing]);

    return <TabContainer>
        <div className="flex flex-col overflow-auto">
            <Row>
                <Col span={24}>
                    <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                        <BackButton onClick={navigateBack} >{t("admin.costManagement")}</BackButton>
                    </div>
                </Col>
            </Row>
        </div>
        <div style={{ margin: '16px 24px 24px 24px', width: '846px' }}>
            <div style={{ border: `1px solid ${colorBorder}`, borderRadius: '0.75rem', padding: '1rem', background: `${colorBgBase}` }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                    <h5 style={{ lineHeight: '24px', fontSize: '16px', color: colorText, margin: 0 }}>{t("admin.availableCurrencies")}</h5>
                    <div style={{ flex: 1 }}>
                        <Badge
                            count={currencies.filter(x => x.active).length}
                            className='ml-10'
                            style={{ backgroundColor: colorPrimaryBg, color: colorPrimary }} />
                    </div>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {!isEditing && <Button type="primary" onClick={onEdit}>{t("generic.manage")}</Button>}
                        {isEditing && <Popconfirm
                            placement="bottomRight"
                            title={
                            <>
                                {t("admin.wontSave")}
                            </>
                            }
                            okText={t("generic.accept")}
                            onConfirm={onCancel}
                            cancelText="Cancel"
                            icon={<ExclamationCircleFilled />}
                        >
                            <Button type="default"  >{t("generic.cancel")}</Button>
                        </Popconfirm>}
                        {isEditing && <Button type="primary" disabled={unsavedChangesCount === 0} onClick={onSave}>{t("generic.save")}</Button>}
                        {(unsavedChangesCount > 0) && <Badge color='red' count={unsavedChangesCount} style={{ position: 'absolute', marginTop: '-12px', marginRight: '-3px' }} />}
                    </div>
                </div>
                <div className="currencies-table" style={{ border: `1px solid ${colorBorder}` }}>
                    <div className="currencies-table-header" style={{ background: `${colorFillTertiary}`, borderBottom: `1px solid ${colorBorder}` }}>
                        <div className="name-field">Name</div>
                        <div className="unit-field">Unit</div>
                        <div className="symbol-field">Symbol</div>
                        <div className="isocode-field">ISO code</div>
                        <div className="isonumber-field">ISO number</div>
                        <div className="status-field">Status</div>
                    </div>
                    <div className="currencies-table-body">
                        {currenciesSorted.map(e => 
                            <div key={e.id} className={"currencies-table-row " + (e.active ? '' : 'disabled')}>
                                <div className="name-field">{e.name}</div>
                                <div className="unit-field">{e.unit}</div>
                                <div className="symbol-field">{e.symbol}</div>
                                <div className="isocode-field">{e.isoCode}</div>
                                <div className="isonumber-field">{e.isoNumber}</div>
                                <div className="status-field">
                                    <Switch checked={e.active} disabled={!isEditing} onClick={(x) => { onClickVisibility(e.id) }} />
                                    <div style={{ color: (e.active ? colorPrimary : colorTextDisabled) }}>{e.active ? t("admin.visible") : t("admin.hidden")}</div>
                                    {e.active && <EyeOutlined style={{ color: colorPrimary }}/>}
                                    {!e.active && <EyeInvisibleOutlined />}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </TabContainer>
}