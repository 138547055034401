import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { MarkupAnnotationIcon } from '@components/Icons/MarkupAnnotationIcon';

interface IMarkupAnnotationFlagProps {
  handleOnClick?: () => void;
}

export const MarkupAnnotationFlag = (props: IMarkupAnnotationFlagProps) => {
  const { handleOnClick } = props;

  const { t } = useTranslation();

  return (
    <Tooltip placement="top" title={t('attachment.markup.flagMessage')}>
      <MarkupAnnotationIcon className="cursor-pointer" onClick={handleOnClick} />
    </Tooltip>
  );
};
