import { isFulfilled, isPending, createAsyncThunk } from '@reduxjs/toolkit';

import { createEntitySlice, EntityState, serializeAxiosError } from '../reducer.util';
import { shapeService } from '../../services/shapes.service';
import { IQueryParams } from '../../models/pagination';
import { cleanEntity } from '../../shared/util/entity-utils';
import { IShape, defaultValue } from '@models/shape.model';

export const getEntities = createAsyncThunk(
  'shape/fetch_entity_list',
  async ({ attachmentId, pageNumber, queryParams }: { attachmentId: number; pageNumber: number; queryParams: IQueryParams }) => {
    return shapeService.getShapes(attachmentId, pageNumber, queryParams);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'shape/create_entity',
  async ({ entity, attachmentId, pageNumber }: { entity: IShape; attachmentId: number; pageNumber: number }, thunkAPI) => {
    const result = await shapeService.createShape(cleanEntity(entity), attachmentId, pageNumber);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'shape/update_entity',
  async (entity: IShape, thunkAPI) => {
    const result = entity.id && (await shapeService.updateShape(entity.id, cleanEntity(entity)));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const patchEntity = createAsyncThunk(
  'shape/patch_entity',
  async (entity: IShape, thunkAPI) => {
    const result = entity.id && (await shapeService.patchShape(entity.id, cleanEntity(entity)));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'shape/delete_entity',
  async (id: string, thunkAPI) => {
    const result = await shapeService.deleteShape(id);
    return result;
  },
  { serializeError: serializeAxiosError }
);

const initialState: EntityState<IShape> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export const ShapeSlice = createEntitySlice({
  name: 'shape',
  initialState,
  reducers: {
    clearEntity: state => {
      state.entity = defaultValue;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const data = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: data.length,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, patchEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload as IShape;
      })
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, patchEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, clearEntity } = ShapeSlice.actions;

// Reducer
export default ShapeSlice.reducer;
