/* eslint-disable react-hooks/exhaustive-deps */
import type { TableProps } from 'antd/es/table';
import React, { useState, useEffect } from 'react';
import { ConfigProvider, Table } from 'antd';
import { useAppDispatch, useAppSelector } from '@store/store';
import { useNavigate } from 'react-router-dom';
import { ILocation } from '@models/location.model';
import { getEntities, reset } from '@store/slices/location';
import { OnRowEvent } from '@models/utils/table.utils.model';
import { ToolBar } from '../ItemsVisualizations/ToolBar/ToolBar';
import { ItemsVisualization } from '@components/ItemsVisualizations/ItemsVisualization';
import { If } from '@components/Utils/Structural';
import { IHandleOnSearchProps } from '@components/Utils/SearchTable';
import { LocationsMapView } from './LocationsMapView/LocationsMapView';
import { ResizableCell } from '@components/Utils/Table/TableUtils';
import { ButtonNewLocation, DEFAULT_PAGINATION, PaginationLocations, SearchFilter, TitleLocations, TotalItems, usePaginationUrlParams, useTextFilterUrlParam } from './LocationsVisualization/ToolbarComponents';
import { IVISUALIZATIONS_TYPE, VISUALIZATIONS, ViewOptions } from './LocationsVisualization/VisualizationsOptions';
import { useLocationContainerContex } from './LocationsVisualization/LocationsContainer';
import { useLocationsColumns } from './LocationsVisualization/LocationsTableComponents';
import { SorterResult } from 'antd/es/table/interface';

const Locations: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { columns } = useLocationsColumns();

  const [nameFilter, setNameFilter] = useState('');

  const { entities: locationList, totalItems, loading } = useAppSelector(state => state.Location);
  const { heightContainer } = useLocationContainerContex();

  const { getTextFilterUrlParam } = useTextFilterUrlParam();
  const { getPaginationUrlParamPage, getPaginationUrlParamSize, getPaginationUrlParamSort, setPaginationUrlParamSort } =
    usePaginationUrlParams();

  const [tableParams, setTableParams] = useState({
    current: getPaginationUrlParamPage() ?? 0,
    size: getPaginationUrlParamSize() ?? DEFAULT_PAGINATION,
    sort: getPaginationUrlParamSort() ?? `name,asc`,
    nameFilter: getTextFilterUrlParam() || nameFilter,
  });

  const filterLocations = () => {
    const { current, size, sort, nameFilter } = tableParams;
    dispatch(
      getEntities({
        page: current,
        size: size,
        sort: sort,
        nameFilter: nameFilter,
      })
    );
  };

  useEffect(() => {
    filterLocations();
  }, [tableParams]);

  const openFormNewLocation = () => {
    dispatch(reset());
    navigate('/location/new');
  };

  const onChangeTable: TableProps<ILocation>['onChange'] = (pagination, filters, sorter, extra) => {
    const _sorter = sorter as SorterResult<ILocation>;

    setTableParams(oldValue => {
      let sortField = _sorter?.field as string;
      //since we want to sort by the string instead of the id we need to change the field
      if (sortField === 'brand' || sortField === 'market') {
        sortField += '.description';
      }
      const sortOrder = _sorter?.order === 'descend' ? 'desc' : _sorter?.order === 'ascend' ? 'asc' : 'asc';
      setPaginationUrlParamSort(`${sortField},${sortOrder}`);
      return {
        ...oldValue,
        ...(pagination?.pageSize && { size: pagination.pageSize }),
        current: pagination.current !== undefined ? pagination.current - 1 : getPaginationUrlParamPage() ?? 0,
        sort: `${sortField},${sortOrder}`,
      };
    });
  };

  const onRowClick = (eventOnClick: OnRowEvent<ILocation>) => {
    const { event, record } = eventOnClick;
    const urlToNavigate = `/location/${record.id}`;

    event?.metaKey ? window.open(`${urlToNavigate}`) : navigate(`${urlToNavigate}`);
  };

  const [visualization, setVisualization] = useState<IVISUALIZATIONS_TYPE>(VISUALIZATIONS.TABLE);

  const handleOnSearch = (props?: IHandleOnSearchProps) => {
    const { valueToSearch = '' } = props as IHandleOnSearchProps;
    setNameFilter(valueToSearch);
    setTableParams(oldValue => {
      return {
        ...oldValue,
        current: 0,
        size: DEFAULT_PAGINATION,
        nameFilter: valueToSearch,
      };
    });
  };

  return (
    <ItemsVisualization>
      <ToolBar
        title={<TitleLocations />}
        totalItems={<TotalItems totalItems={totalItems} />}
        settingTool={<ViewOptions setVisualization={setVisualization} visualization={visualization} />}
        pagination={<PaginationLocations totalItems={totalItems} tableParams={tableParams} setTableParams={setTableParams} />}
        search={<SearchFilter handleOnSearch={handleOnSearch} />}
        actionButton={<ButtonNewLocation openFormNewLocation={openFormNewLocation} />}
      />
      <If condition={!!(`${visualization}` === `${VISUALIZATIONS.TABLE}`)}>
        <ConfigProvider theme={{ token: { borderRadius: 4 } }}>
          <Table
            className="mt-20 bordered-custom-table"
            loading={loading}
            rowKey="id"
            size="small"
            components={{
              header: {
                cell: ResizableCell,
              },
            }}
            columns={columns}
            dataSource={locationList}
            onChange={onChangeTable}
            bordered={false}
            rowClassName={'hover-in-row'}
            onRow={(record, rowIndex) => ({
              onClick: event => onRowClick({ event, record, rowIndex }),
            })}
            scroll={{ y: heightContainer - 150 }}
            pagination={false}
          />
        </ConfigProvider>
      </If>
      <If condition={!!(`${visualization}` === `${VISUALIZATIONS.MAP}`)}>
        <LocationsMapView locationsList={[...locationList]} loading={loading} />
      </If>
    </ItemsVisualization>
  );
};

export default Locations;