import { SVGProps } from 'react';

import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

type IProps = SVGProps<SVGSVGElement>;

interface IRectangleProps extends IProps {
  isActive: boolean;
}

export const RectangleSvgIcon = (props: IRectangleProps) => {
  const { isActive, ...restProps } = props;
  const { t } = useTranslation();

  return (
    <Tooltip placement="bottom" title={t('markup.drawRectangle')}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
        <path d="M3 3H21V21H3V3ZM5 5V19H19V5H5Z" fill={isActive ? '#1890FF' : '#292D2D'} fillOpacity={isActive ? '1' : '0.45'} />
      </svg>
    </Tooltip>
  );
};
