import { http } from '../http/axios.instance';
import { IFeatureFlagEvalResult } from '@models/feature-flag-eval-result.model';

const path = 'eval-feature-flags';

export const featureFlagEvaluationRepository = {
  getAll: async () => {
    const requestUrl = `${path}?cacheBuster=${new Date().getTime()}`;
    const promiseGetAll = await http.get<IFeatureFlagEvalResult[]>(requestUrl);
    return promiseGetAll;
  },
};
