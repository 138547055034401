/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useErrorAnimation } from "@HOOKs/UseErrorAnimation";
import { isFulfilled } from "@reduxjs/toolkit";
import { asyncLaunchNotification } from "@store/slices/notification";
import { createEntity, updateEntity, getPunchListStatuses } from "@store/slices/punch-list-status";
import { useAppDispatch, useAppSelector } from "@store/store";
import { Button, Col, Drawer, Form, Input, Row, Space, Switch, theme } from "antd";
import { useTranslation } from "react-i18next";
import { IPunchListItemStatus } from "@models/punch-list.model";
interface IPunchListItemStatusEditProps {
  open: boolean,
  toogle: () => void,
  onModifiedChanges?: (punchListItemStatus: IPunchListItemStatus) => void,
  punchListItemStatus: IPunchListItemStatus
}

export const StatusEdit = (props: IPunchListItemStatusEditProps) => {

    const { open, toogle, punchListItemStatus, onModifiedChanges } = props;

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { token: { colorTextDisabled, colorSuccessText } } = theme.useToken();

    const [form] = Form.useForm();
    const defaultOption = Form.useWatch('defaultOption', form);
    const finalState = Form.useWatch('finalState', form);

    const { loading, updating } = useAppSelector((state) => state.PunchListStatus);

    const [animateError, playAnimationOfError] = useErrorAnimation();

    const onFinishFailed = (errorInfo: any) => playAnimationOfError();

    const onFinish = (entity: any): void => {
        const hasPunchListItemStatus = punchListItemStatus?.id;
        if (hasPunchListItemStatus) {
            dispatch(
                updateEntity({
                    ...punchListItemStatus,
                    ...entity,
                    status: entity?.status?.trim() || '',
                    defaultOption: defaultOption,
                    finalState: finalState
                })  
            ).then(onSuccessUpdatedStatus);
            
        } else {
            dispatch(createEntity({
                id: "",
                ...entity,
                status: entity?.status?.trim() || '',
                defaultOption: defaultOption,
                finalState: finalState
            })).then(onSuccessCreateStatus);
        }
    };

    const onSuccessUpdatedStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: t("admin.changesSaved"),
                  description: t("admin.yourChangesHaveBeenSavedSuccessfully")
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onSuccessCreateStatus = (response: any) => {
        if (isFulfilled(response)) {
            dispatch(asyncLaunchNotification({
                type: "success",
                config: {
                  message: t("admin.changesSaved"),
                  description: t("admin.statusCreatedSuccessfully")
                }
            }))
            onModifiedChanges && onModifiedChanges(response?.payload?.data ? response?.payload?.data : {});
            onClose();
        } else { 
            playAnimationOfError() 
        }
    }

    const onClose = () => { 
        form.resetFields();
        toogle(); 
    }

    const onSubmit = () => form.submit();

    const loadPunchListStatuses = () => {
        dispatch(
            getPunchListStatuses()
        );
      }
    
      useEffect(() => {
        loadPunchListStatuses();
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    useEffect(() => { setRoleFormFields() }, [punchListItemStatus])

    const setRoleFormFields = () => {
        form.setFieldsValue({
            ...punchListItemStatus,
            defaultOption: Boolean(punchListItemStatus?.defaultOption),
            finalState: Boolean(punchListItemStatus?.finalState)
        });
    }

    return (
        <>
            <Drawer 
                width={448} 
                title={`${punchListItemStatus?.id ? t("admin.editStatus"): t("admin.createStatus")}`}
                placement="right" 
                onClose={onClose} 
                open={open}
                closable={false}
                extra={
                    <Space>
                      <Button type="primary" ghost onClick={onClose}>
                        {t("generic.cancel")}
                      </Button>
                      <Button loading={loading} onClick={onSubmit} type="primary" disabled={updating}>
                        {`${punchListItemStatus?.id ? t("generic.save"): t("generic.create")}`}
                      </Button>
                    </Space>
                  }
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className={`${animateError && "error-animation"}`}
                >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="status"
                                label={t("admin.status")}
                                rules={[
                                    { required: true, message: t("admin.statusFieldRequired") },
                                ]}
                            >
                                <Input placeholder={t("admin.status")} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={"none"} className="pr-0" style={{ flex: '1' }}>
                            {t("admin.default")}
                        </Col>
                        <Col span={"none"} className="pl-0" style={{ flex: '1', textAlign: 'right' }} >
                            {/* For css purposes */}
                            <Form.Item className="ml-0 pl-0" valuePropName="">
                                <span style={{ color: (Boolean(defaultOption) ? colorSuccessText : colorTextDisabled) }}>{Boolean(defaultOption) ? 'On' : 'Off'}</span>
                                <Switch style={{ display: "none" }}/>
                            </Form.Item>
                        </Col>
                        <Col span={"none"} className="pr-0" style={{ flex: '0', textAlign: 'right' }}>
                            <Form.Item name="defaultOption" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={"none"} className="pr-0" style={{ flex: '1' }}>
                            {t("admin.finalState")}
                        </Col>
                        <Col span={"none"} className="pl-0" style={{ flex: '1', textAlign: 'right' }} >
                            {/* For css purposes */}
                            <Form.Item className="ml-0 pl-0" valuePropName="">
                                <span style={{ color: (Boolean(finalState) ? colorSuccessText : colorTextDisabled) }}>{Boolean(finalState) ? 'On' : 'Off'}</span>
                                <Switch style={{ display: "none" }}/>
                            </Form.Item>
                        </Col>
                        <Col span={"none"} className="pr-0" style={{ flex: '0', textAlign: 'right' }}>
                            <Form.Item name="finalState" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
        </>
  );
}
