import { http } from '../http/axios.instance';

import { IShapeResponse } from '../../models/shape-response.model';
import { IQueryParams } from '../../models/pagination';
import { ShapeMapper } from '@mappers/Shape.mapper';
import { IShape } from '@models/shape.model';

const path = 'shapes';

export const shapeRepository = {
  getShapes: async (attachmentId: number, pageNumber: number, queryParams: IQueryParams) => {
    const { sort, page, size } = queryParams;
    const requestUrl = `${path}?attachmentId.equals=${attachmentId}&pageNumber.equals=${pageNumber}&${
      sort ? `page=${page}&size=${size}&sort=${sort}&` : ''
    }cacheBuster=${new Date().getTime()}`;
    const shapesResponse = await http.get<IShapeResponse[]>(requestUrl);
    const shapes = ShapeMapper.toDomain(shapesResponse.data);
    return shapes;
  },

  createShape: async (entity: IShape, attachmentId: number, pageNumber: number): Promise<IShape> => {
    const requestUrl = `${path}`;
    const payload = ShapeMapper.convertToShapeResponseSingle(entity, attachmentId, pageNumber);
    const shapeResponse = await http.post<IShapeResponse>(requestUrl, payload);
    const shape = ShapeMapper.toDomainSingle(shapeResponse.data);
    return shape;
  },

  updateShape: async (id: string | number, entity: IShape): Promise<IShape> => {
    const requestUrl = `${path}/${id}`;
    const payload = ShapeMapper.convertToShapeResponseSingle(entity);
    const shapeResponse = await http.put<IShapeResponse>(requestUrl, payload);
    const shape = ShapeMapper.toDomainSingle(shapeResponse.data);
    return shape;
  },

  patchShape: async (id: string | number, entity: IShape): Promise<IShape> => {
    const requestUrl = `${path}/${id}`;
    const payload = ShapeMapper.convertToShapeResponseSingle(entity);
    const shapeResponse = await http.patch<IShapeResponse>(requestUrl, payload);
    const shape = ShapeMapper.toDomainSingle(shapeResponse.data);
    return shape;
  },

  deleteShape: async (id: string | number) => {
    const requestUrl = `${path}/${id}`;
    const shapeResponse = await http.delete<IShapeResponse>(requestUrl);
    const shape = ShapeMapper.toDomainSingle(shapeResponse.data);
    return shape;
  },
};
