import { TabContainer } from "@components/Utils/TabContainer"
import { Col, Row, theme } from "antd"
import { BackButton } from "@components/Utils/BackButton"
import { useNavigate } from "react-router-dom"
import { DefaultCurrencyCard } from "./DefaultCurrencyCard"
import { CostGroupsCard } from "./CostGroupsCard"
import { useLayoutContex } from "@providers/LayoutProvider"
import { useEffect } from "react"
import { AvailableCurrenciesCard } from "./AvailableCurrenciesCard"

export const CostManagementConfig = () => {

    const { token: { colorBgContainer, colorBorderSecondary } } = theme.useToken();

    const navigate = useNavigate();
    const { setHeaderLabelEntityState } = useLayoutContex()
    
    useEffect(() => {
        setHeaderLabelEntityState('Cost Management');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const navigateBack = () => {
        navigate('/admin/application-configuration')
    }

    return (
        <TabContainer>
            <div className="flex flex-col overflow-auto">
                <Row>
                    <Col span={24}>
                        <div className="flex flex-row items-center pl-10 pt-11 pb-11" style={{ borderTop: `1px solid ${colorBorderSecondary}`, borderBottom: `1px solid ${colorBorderSecondary}`, backgroundColor: colorBgContainer }}>
                            <BackButton onClick={navigateBack} >Application Configuration</BackButton>
                        </div>
                    </Col>
                </Row>
            </div>
            <div style={{ marginLeft: '24px', marginTop: '16px' }}>
                <DefaultCurrencyCard />
                <CostGroupsCard />
                <AvailableCurrenciesCard />
            </div>
        </TabContainer>
    )
}