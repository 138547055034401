import { Modal, Tooltip, theme } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { TrashSvgIcon } from "@components/Icons/TrashSvgIcon";
import { IAttachment } from "@models/attachment.model";
import { useAppDispatch } from "@store/store";
import { deleteEntity as deleteAttachment } from '@store/slices/attachment';
import { useTranslation } from 'react-i18next';

interface IDeleteButtonProps {
  attachment: IAttachment;
  onSuccessDeleteAttachment: () => void;
  onFinishProcessDelete: () => void;
  disabled?: boolean;
}

export const DeleteButton = (props: IDeleteButtonProps) => {
  const { attachment, onFinishProcessDelete, onSuccessDeleteAttachment, disabled = false } = props;

  const {
    token: { colorErrorBorderHover, colorFill },
  } = theme.useToken();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const onSuccessDelete = () => {
    onSuccessDeleteAttachment?.();
  };

  const [modal, contextHolder] = Modal.useModal();

  const onDeleteAction = () => {
    if (!attachment.id) return;

    modal.confirm({
      styles: { content: { padding: 16, borderRadius: 4 } },
      title: 'Do you Want to delete this file?',
      icon: <ExclamationCircleFilled />,
      onOk() {
        attachment.id &&
          dispatch(deleteAttachment({ id: attachment.id, onSuccess: onSuccessDelete })).then(() => {
            onFinishProcessDelete?.();
          });
      },
    });
  };

  return (
    <>
      <>{contextHolder}</>
      <Tooltip placement="bottom" title={t('attachment.panels.delete')}>
        <TrashSvgIcon
          className={`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={disabled ? undefined : onDeleteAction}
          height="20"
          width="20"
          style={{ color: disabled ? colorFill : colorErrorBorderHover }}
        />
      </Tooltip>
    </>
  );
};
