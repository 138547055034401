import { SVGProps } from 'react';

import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

type IProps = SVGProps<SVGSVGElement>;

interface IArrowProps extends IProps {
  isActive: boolean;
}

export const ArrowUpRightSvgIcon = (props: IArrowProps) => {
  const { isActive, ...restProps } = props;
  const { t } = useTranslation();

  return (
    <Tooltip placement="bottom" title={t('markup.drawArrow')}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
        <path
          d="M5 17.59L15.59 7H9V5H19V15H17V8.41L6.41 19L5 17.59Z"
          fill={isActive ? '#1890FF' : '#292D2D'}
          fillOpacity={isActive ? '1' : '0.45'}
        />
      </svg>
    </Tooltip>
  );
};
