import { CSSProperties } from "react";
import { FileImageOutlined, FilePdfOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { EmptyLabel } from '@components/Utils/EmptyLabel';
import { If } from '@components/Utils/Structural';
import { TextEllipsis } from '@components/Utils/TextEllipsisContent';
import { IAttachment } from '@models/attachment.model';
import { formatDatePipe } from '@shared/util/date-utils';
import { convertBytes, getExtensionOfFileName, isAnValidImage } from '@shared/util/document-utils';
import { Col, Row, theme } from 'antd';
import { capitalize } from 'lodash';
import { orange, gold } from '@ant-design/colors';
import { useTranslation } from 'react-i18next';

interface IInfoSideProps {
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  containerClassName?: string;
  containerStyles?: CSSProperties;
}

export const InfoSide = (props: IInfoSideProps) => {
  const { header, body, footer, containerClassName, containerStyles } = props;

  return (
    <div className={`flex flex-col w-full ${containerClassName}`} style={containerStyles}>
      {header}
      {body}
      {footer}
    </div>
  );
};

export const HeaderInfoSide = () => {
  const {
    token: { fontSizeLG, colorTextSecondary, colorFillTertiary, colorFillSecondary },
  } = theme.useToken();

  const { t } = useTranslation();

  return (
    <div
      className="flex flex-row items-center"
      style={{
        background: colorFillTertiary,
        borderBottom: `1px solid ${colorFillSecondary}`,
      }}
    >
      <span className="pl-30 pt-10 pb-10 font-medium" style={{ fontSize: fontSizeLG, color: colorTextSecondary }}>
        {t('attachment.panels.fileInformation')}
      </span>
    </div>
  );
};

interface IBodyInfoSideProps {
  attachment: IAttachment;
  bodyContainerClassName?: string;
  bodyContainerStyle?: CSSProperties;
  showRevisionPanel?: boolean;
  previewLatestVersion?: () => void;
}

export const BodyInfoSide = (props: IBodyInfoSideProps) => {
  const { attachment, bodyContainerClassName, bodyContainerStyle, showRevisionPanel = true, previewLatestVersion = undefined } = props;

  const { t } = useTranslation();

  const {
    token: { fontSizeSM, colorTextSecondary, colorTextTertiary },
  } = theme.useToken();

  const { versions = [] } = attachment;
  const latestVersion = versions[0] || null;

  return (
    <div className={bodyContainerClassName} style={bodyContainerStyle}>
      <Row className="pb-8">
        <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextSecondary }} className="font-bold">
          {t('attachment.fileFormat')}:
        </Col>
        <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextTertiary }} className="flex flex-row items-center">
          {isAnValidImage(getExtensionOfFileName(`${attachment?.fileName}`)) ? (
            <FileImageOutlined className="mr-5" />
          ) : (
            <FilePdfOutlined className="mr-5" />
          )}
          {isAnValidImage(getExtensionOfFileName(`${attachment?.fileName}`))
            ? `${getExtensionOfFileName(`${attachment?.fileName}`).toUpperCase()} Image `
            : `${getExtensionOfFileName(`${attachment?.fileName}`).toUpperCase()} File`}
        </Col>
      </Row>
      <Row className="pb-8">
        <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextSecondary }} className="font-bold">
          {t('attachment.fileSize')}:
        </Col>
        <Col span={12} style={{ color: colorTextTertiary, fontSize: fontSizeSM }}>
          {(attachment.fileSize && convertBytes(attachment.fileSize)) || <EmptyLabel />}
        </Col>
      </Row>
      <Row className="pb-8">
        <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextSecondary }} className="font-bold">
          {t('attachment.uploadedBy')}:
        </Col>
        <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextTertiary }}>
          <If condition={!!attachment.createdBy}>
            <TextEllipsis
              text={capitalize(`${attachment.createdByFirstName} ${attachment.createdByLastName}`)}
              ellipsis={{
                tooltip: {
                  title: attachment.createdByEmail,
                  rootClassName: 'fullMaxWidth',
                },
              }}
              style={{ fontSize: fontSizeSM, color: colorTextTertiary }}
            />
          </If>
        </Col>
      </Row>
      <Row className="pb-8">
        <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextSecondary }} className="font-bold">
          {t('attachment.uploadDate')}:
        </Col>
        <Col span={12} style={{ fontSize: fontSizeSM, color: colorTextTertiary }}>
          {(attachment.createdDate && formatDatePipe(attachment.createdDate)) || <EmptyLabel />}
        </Col>
      </Row>

      <If condition={Boolean(showRevisionPanel)}>
        <If condition={latestVersion !== null && latestVersion?.id !== attachment.id}>
          <div
            className="mt-20 pt-20 pb-20 pl-20 pr-20"
            style={{ border: `1px solid ${orange[2]}`, color: gold[9], backgroundColor: gold[0], borderRadius: 4 }}
          >
            <Row className="pb-4 text-center">
              <Col span={24} className="font-bold">
                <InfoCircleOutlined className="" style={{ color: gold[5] }} />
              </Col>
            </Row>
            <Row className="pb-4 text-center pt-5">
              <Col span={24} className="font-normal pr-20 pl-20">
                {t('attachment.notLatestVersion')}
                <If condition={previewLatestVersion !== undefined}>
                  <span className="pl-4 underline cursor-pointer" onClick={() => previewLatestVersion?.()}>
                    {t('attachment.clickToViewLatestVersion')}
                  </span>
                </If>
              </Col>
            </Row>
          </div>
        </If>
      </If>
    </div>
  );
};
