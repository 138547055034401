import { CommentSvgIcon } from "@components/Icons/CommentSvgIcon";
import { Tooltip, theme } from "antd";
import { useTranslation } from 'react-i18next';

interface ICommentButtonProps {
  handleOnClick: () => void;
  isActive: boolean;
}

export const CommentButton = (props: ICommentButtonProps) => {
  const { handleOnClick, isActive } = props;

  const {
    token: { colorPrimary, colorTextTertiary },
  } = theme.useToken();

  const { t } = useTranslation();

  return (
    <Tooltip placement="bottom" title={t('generic.comments')}>
      <CommentSvgIcon
        className="cursor-pointer"
        onClick={handleOnClick}
        height="22"
        width="22"
        style={{ color: isActive ? colorPrimary : colorTextTertiary }}
      />
    </Tooltip>
  );
};
